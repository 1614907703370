import React from "react";
import Image from "gatsby-plugin-sanity-image";
import styled from "styled-components";
import moment from "moment";
import { DateTime } from "luxon";

export default function EventList({ events, limit }) {
  return events.map((event, idx) => {
    if (limit && idx >= limit) return null;

    const eventDetails = JSON.parse(event.node.json);

    // return <>{JSON.stringify(eventDetails)}</>

    let eventTime = DateTime.fromISO(event.node.startTime);
    eventTime = eventTime.setZone("America/Chicago");

    let localTime = eventTime.toFormat("cccc M/dd h:mm a").toString();

    let mediaURL = eventDetails.eventMedia?.[0]?.publicURL ?? "";
    if (mediaURL === undefined || mediaURL === null || mediaURL === "")
      mediaURL = eventDetails.artist?.artistMedia?.[0]?.publicURL ?? "";

    let mediaType = eventDetails.eventMedia?.[0]?.mediaType ?? "";
    if (mediaType === undefined || mediaType === null || mediaType === "")
      mediaType = eventDetails.artist?.artistMedia?.[0]?.mediaType ?? "";

    let title = eventDetails.shortDescription ?? "";
    if (title === undefined || title === null || title === "")
      title = eventDetails.artist?.artistName ?? "";

    let description = eventDetails.longDescription ?? "";
    if (description === undefined || description === null || description === "")
      description = eventDetails.artist?.description ?? "";

    return (
      <div className="eventContainer" key={event.eventId}>
        <div className="eventImageContainer left">
          {mediaURL !== "" && (
            <img className="eventImage" src={mediaURL} alt={title + " image"} />
          )}
        </div>
        <div className="eventDetails">
          <h3>{localTime}</h3>

          <h4>{title}</h4>
          <div>{description}</div>
        </div>

        <div className="eventImageContainer right">
          {mediaURL !== "" && (
            <img className="eventImage" src={mediaURL} alt={title + " image"} />
          )}
        </div>
      </div>
    );
  });
}
