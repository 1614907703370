import * as React from "react";
import { graphql } from "gatsby";
import Image from "gatsby-plugin-sanity-image";
import Layout from "../components/layout";
import styled from "styled-components";
import moment from "moment";
import EventList from "../components/events/EventList";

const EventContainer = styled.div`
  .eventContainer {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 30px 0px;
    border-bottom: 3px solid #ededed;

    .eventImageContainer {
      flex: 2;
      display: inline-block;
      border: 3px solid #ccc;
      background: #eee;
      margin: auto;
      padding: 10px 10px;

      @media only screen and (min-width: 600px) {
        &.right {
          transform: rotate(2deg);
        }
        &.left {
          transform: rotate(-2deg);
        }
      }
    }

    .eventImageContainer.left {
      display: block;
    }

    .eventImageContainer.right {
      display: none;
    }

    @media only screen and (min-width: 600px) {
      flex-direction: row;

      .eventImageContainer.left {
        display: none;
      }

      :nth-child(even) .eventImageContainer.left {
        display: block;
        flex: 2;
      }

      :nth-child(odd) .eventImageContainer.right {
        display: block;
        flex: 2;
      }
    }
    .eventDetails {
      flex: 1;
      @media only screen and (min-width: 600px) {
        flex: 3;
      }
      padding: 0px 20px;

      h3 {
        font-size: 24px;
        margin-bottom: 20px;
      }

      h4 {
        font-size: 28px;
        margin-bottom: 5px;
      }
    }

    .eventImage {
      width: 100%;
    }
  }
`;

// markup
const EventsPage = (data) => {
  return (
    <Layout title="Live Music">
      <EventContainer>
        <EventList limit={20} events={data.data.allEvent.edges}></EventList>
      </EventContainer>
    </Layout>
  );
};

export const query = graphql`
  query EventPageQuery {
    allEvent(sort: { fields: startTime, order: ASC }) {
      edges {
        node {
          json
          startTime
        }
      }
    }
  }
`;

export default EventsPage;
